import * as Yup from "yup";
import { SchemaObject } from "../../../../../components/type";
import { StaffToRetainFormData } from "./DowngradeModal";

export const StaffToRetainValidation = Yup.object().shape<
  SchemaObject<StaffToRetainFormData>
>({
  staffToRetain: Yup.array()
    .nullable()
    .max(2, "Maximum of two staff")
    .of(Yup.object().nullable().required("Required")),
});
