import React, { useEffect, useState } from "react";
import Hero from "./components/Hero";
// import Offers from "./components/Offers";
// import Steps from "./components/Steps";
// import Process from "./components/Process";
// import About from "./components/About";
// import Testmonial from "./components/Testmonial";
import ForIndividual from "./components/ForIndividual";
import ForBusinesses from "./components/ForBusinesses";
import FiBiSection from "./components/FiBiSection";
import FiBiPopUp from "../components/FiBiPopUp";
import SEOMeta from "../components/SEOMeta";
import { WebPaths } from "../../../routes/data";
import { SVLogoSquare } from "../../../images";
// import Consultation from "./components/Consultation";
// import News from "./components/News";

const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check if the user has already dismissed the modal in this session
    const hasSeenModal = sessionStorage.getItem("hasSeenModal");

    if (!hasSeenModal) {
      setIsModalOpen(true);
      sessionStorage.setItem("hasSeenModal", "true"); // Set flag for this session
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <SEOMeta
        title="SeamlessVisa - Simplifying U.S. Visa Applications"
        description="Get the expertise of an immigration lawyer without the price tag. Apply for your green card or fiancé visa online with SeamlessVisa."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.Root}`}
        url={`https://www.seamlessvisa.com${WebPaths.Root}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="SeamlessVisa - Simplifying U.S. Visa Applications"
        twitterDescription="Get the expertise of an immigration lawyer without the price tag. Apply for your green card or fiancé visa online with SeamlessVisa."
        twitterSite="@SeamlessVisa_"
      />
      <Hero />
      <ForIndividual />
      <ForBusinesses />
      <FiBiSection />
      {/* <Offers /> */}
      {/* <Steps /> */}
      {/* <Process /> */}
      {/* <About /> */}
      {/* <Testmonial /> */}
      {/* <Consultation /> */}
      {/* <News /> */}
      <FiBiPopUp isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default LandingPage;
