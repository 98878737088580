import { Outlet } from "react-router";
// import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/NavBar";
import ChatSystem from "../landing page/components/ChatSystem";
import { useSnapshot } from "valtio";
import { isBusinessDataStore } from "../../../store/isBusinessData";
import { isAnonClientDataStore } from "../../../store/isAnonClientData";

const Layout = () => {
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);

  return (
    <>
      {/* <Header /> */}
      <Navbar />
      {/* <div className="mt-24"> */}
      <Outlet />
      {/* </div> */}
      {!isBusinessData && !isAnonClientData && <ChatSystem />}
      <Footer />
    </>
  );
};

export default Layout;
