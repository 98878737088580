import React from "react";
import { SubTitle, Wrapper } from "../styles";
import { Form, Formik } from "formik";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import { FormWrapper } from "../../../components/styles";
import SelectField from "../../../components/formik fields/SelectField";
import {
  ExplicitNGDateFormat,
  ToSaveDate,
  initCheck,
  initDate,
  initInput,
  initSelect,
  resetDynamicFields,
} from "../../../components/data";
import {
  DS160MaritalStatusData,
  DS160MaritalStatusValues,
  PersonAddressOptions,
  PersonAddressOptionsValues,
  processReviewData,
} from "../data";
import InputField from "../../../components/formik fields/InputField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import useCountries from "../../../components/useCountries";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { MaritalStatusValidation } from "../validation";
import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetDS160MaritalStatus,
  useSaveDS160MaritalStatus,
} from "../../../../../hooks/ds160/usDS160";
import FormBtn from "../../../components/FormBtn";
import { Title } from "../../../form 400/components/styles";
import useAiReview from "../useAiReview";
import { ReviewFamilySectionB } from "../Review/components/data";
import {
  BusinessApplicationId,
  BusinessQueryKey,
  ClentAnonId,
} from "../../../../../api/utils/types";
import { useSearchParams } from "react-router-dom";
import { isAnonClientDataStore } from "../../../../../store/isAnonClientData";
import { useSnapshot } from "valtio";

export interface saveDS160MaritalStatusObj
  extends BusinessApplicationId,
    ClentAnonId {
  maritalStatus: string;
  personSurname: string;
  personGivenName: string;
  personDateOfBirth: string;
  personCountry: string;
  personCity: string;
  personCityDoNotKnow: boolean;
  personBirthCountry: string;
  personAddress: string;
  personAddressStreetAddressLine1: string;
  personAddressStreetAddressLine2: string;
  personAddressCity: string;
  personAddressState: string;
  personAddressZipCode: string;
  personAddressCountry: string;
  doesNotApplyPersonAddressState: boolean;
  doesNotApplyPersonAddressZipCode: boolean;
}

export interface MaritalStatusFormData
  extends Modify<
    saveDS160MaritalStatusObj,
    {
      maritalStatus: SingleOption | null;
      personDateOfBirth: Dayjs | null;
      personCountry: SingleOption | null;
      personAddress: SingleOption | null;
      personBirthCountry: SingleOption | null;
      personAddressState: SingleOption | null;
      personAddressCountry: SingleOption | null;
    }
  > {}

const MaritalStatus = () => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160MaritalStatus({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { mutate, isLoading: isSaving } = useSaveDS160MaritalStatus();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const { formattedCountryData, countryLoading } = useCountries();

  // const {
  //   formattedCountryData: personPayingCountry,
  //   countryLoading: personCountryLoading,
  // } = useCountries();
  const {
    formattedStateData: personAddressState,
    stateLoading: personAddressStateLoading,
    selectedCountry: personAddressSelectedCountry,
    setCountry: personAddressSetCountry,
  } = useStatesByCountry(data?.personAddressCountry);

  const initialValues: MaritalStatusFormData = {
    maritalStatus: initSelect(DS160MaritalStatusData, data?.maritalStatus),
    personSurname: initInput(data?.personSurname),
    personGivenName: initInput(data?.personGivenName),
    personDateOfBirth: initDate(data?.personDateOfBirth, ExplicitNGDateFormat),
    personCountry: initSelect(formattedCountryData, data?.personCountry),
    personCity: initInput(data?.personCity),
    personCityDoNotKnow: initCheck(data?.personCityDoNotKnow),
    personBirthCountry: initSelect(
      formattedCountryData,
      data?.personBirthCountry
    ),
    personAddress: initSelect(PersonAddressOptions, data?.personAddress),
    personAddressStreetAddressLine1: initInput(
      data?.personAddressStreetAddressLine1
    ),
    personAddressStreetAddressLine2: initInput(
      data?.personAddressStreetAddressLine2
    ),
    personAddressCity: initInput(data?.personAddressCity),
    personAddressState: initSelect(
      personAddressState,
      data?.personAddressState
    ),
    personAddressZipCode: initInput(data?.personAddressZipCode),
    personAddressCountry: initSelect(
      formattedCountryData,
      data?.personAddressCountry
    ),
    doesNotApplyPersonAddressState: initCheck(
      data?.doesNotApplyPersonAddressState
    ),
    doesNotApplyPersonAddressZipCode: initCheck(
      data?.doesNotApplyPersonAddressZipCode
    ),
  };

  const onSubmit = (values: MaritalStatusFormData) => {
    const {
      maritalStatus,
      personDateOfBirth,
      personCountry,
      personAddress,
      personBirthCountry,
      personAddressState,
      personAddressCountry,
      ...rest
    } = values;

    const payload = {
      ...rest,
      maritalStatus: maritalStatus?.value || "",
      personDateOfBirth: ToSaveDate(personDateOfBirth, ExplicitNGDateFormat),
      personCountry: personCountry?.value || "",
      personAddress: personAddress?.value || "",
      personBirthCountry: personBirthCountry?.value || "",
      personAddressState: personAddressState?.value || "",
      personAddressCountry: personAddressCountry?.value || "",
    };

    const section = ReviewFamilySectionB(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      family: {
        ...riskData?.family,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || (!isAnonClientData && riskLoading)}
      isError={isError || (!isAnonClientData && riskError)}
    >
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={MaritalStatusValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <SelectField
                  name="maritalStatus"
                  label="Marital status"
                  placeholder="-Select-"
                  options={DS160MaritalStatusData}
                />
                <div></div>
              </FormWrapper>

              {formik.values.maritalStatus?.value &&
                formik.values.maritalStatus?.value !==
                  DS160MaritalStatusValues.SINGLE && (
                  <>
                    <Title>Provide the following information on partner</Title>
                    <FormWrapper>
                      <InputField
                        label="Surnames"
                        placeholder=""
                        name="personSurname"
                      />
                      <InputField
                        label="Given Names"
                        placeholder=""
                        name="personGivenName"
                      />
                    </FormWrapper>

                    <FormWrapper>
                      <DatePickerField
                        name="personDateOfBirth"
                        label="Date of birth"
                        placeholder={ExplicitNGDateFormat}
                        disableFuture={true}
                      />
                      <SelectField
                        name="personCountry"
                        label="Country/Region"
                        placeholder="-Select-"
                        options={formattedCountryData}
                        isLoading={countryLoading}
                      />
                    </FormWrapper>

                    <SubTitle>Place of Birth</SubTitle>

                    <FormWrapper>
                      <div>
                        <InputField
                          label="City"
                          placeholder=""
                          name="personCity"
                          isDisabled={!!formik.values.personCityDoNotKnow}
                        />

                        <CheckBoxField
                          label={`Does not know`}
                          name="personCityDoNotKnow"
                          onChange={() => {
                            resetDynamicFields(formik, [
                              { name: "personCity", value: "" },
                            ]);
                          }}
                        />
                      </div>

                      <SelectField
                        name="personBirthCountry"
                        label="Birth Country"
                        placeholder="-Select-"
                        options={formattedCountryData}
                        isLoading={countryLoading}
                      />
                    </FormWrapper>

                    <FormWrapper>
                      <SelectField
                        name="personAddress"
                        label="Address"
                        placeholder="-Select-"
                        options={PersonAddressOptions}
                      />

                      <div></div>
                    </FormWrapper>

                    {formik.values.personAddress?.value ===
                      PersonAddressOptionsValues.other && (
                      <>
                        <FormWrapper>
                          <InputField
                            label="Street Address (Line 1)"
                            placeholder=""
                            name="personAddressStreetAddressLine1"
                          />
                          <InputField
                            label="Street Address (Line 2)"
                            coloredLabel="*Optional"
                            placeholder=""
                            name="personAddressStreetAddressLine2"
                          />
                        </FormWrapper>

                        <FormWrapper>
                          <InputField
                            label="City"
                            placeholder=""
                            name="personAddressCity"
                          />

                          <div></div>
                        </FormWrapper>

                        <FormWrapper>
                          <SelectField
                            name="personAddressCountry"
                            label="Country"
                            options={formattedCountryData}
                            isLoading={countryLoading}
                            onChange={(value) => {
                              personAddressSetCountry(value.label);
                              formik.setFieldValue("personAddressState", null);
                            }}
                          />

                          <div className="flex flex-col gap-2 ">
                            <SelectField
                              name="personAddressState"
                              label="State"
                              placeholder="-Select-"
                              options={personAddressState}
                              isLoading={personAddressStateLoading}
                              isDisabled={
                                !personAddressSelectedCountry ||
                                !!formik.values.doesNotApplyPersonAddressState
                              }
                            />

                            <CheckBoxField
                              name="doesNotApplyPersonAddressState"
                              label="Does not apply"
                              onChange={() => {
                                resetDynamicFields(formik, [
                                  { name: "personAddressState", value: null },
                                ]);
                              }}
                            />
                          </div>
                        </FormWrapper>

                        <FormWrapper>
                          <div className="flex flex-col gap-2 ">
                            <InputField
                              label="Postal Zone/ZIP Code"
                              placeholder=""
                              name="personAddressZipCode"
                              isDisabled={
                                !!formik.values.doesNotApplyPersonAddressZipCode
                              }
                            />

                            <CheckBoxField
                              name="doesNotApplyPersonAddressZipCode"
                              label="Does not apply"
                              onChange={() => {
                                resetDynamicFields(formik, [
                                  { name: "personAddressZipCode", value: "" },
                                ]);
                              }}
                            />
                          </div>
                          <div></div>
                        </FormWrapper>
                      </>
                    )}
                  </>
                )}

              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default MaritalStatus;
