import React, { useEffect } from "react";
import {
  billingData,
  BillingStripeQuery,
  BillingType,
} from "./components/data";
import BillingCard from "./components/BillingCard";
import { useSnapshot } from "valtio";
import { businessUserDataStore } from "../../../../../../store/businessUserData";
import { toast } from "react-toastify";
import {
  useBusinessBillingDowngrade,
  useBusinessBillingUpgrade,
  useChoosePaymentMode,
  useSubscriptionPayment,
} from "../../../../../../hooks/business/useBilling";
import { ModuleTitle, SpinnerWrapper } from "../../../../components/styles";
import Spinner from "../../../../../../utils/Spinner";
import { useSearchParams } from "react-router-dom";
import BillingSuccessModal from "./components/BillingSuccessModal";
import PerApplicationConfirmation from "./components/PerApplicationConfirmation";
import useOpener from "../../../../../../hooks/useOpener";
import styled from "styled-components";
import DowngradeModal from "./components/DowngradeModal";
import useGetPrice from "../../../../../../hooks/useGetPrice";
import { StaffSeatPrice } from "../../../../components/data";
import { PayStackParam } from "../../../../form ds 160/components/types";

const Billing = () => {
  const { getPrice, isNigeria } = useGetPrice();
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const [searchParams, setSearchParams] = useSearchParams();
  const billingTypeParams = searchParams.get(
    BillingStripeQuery.billingType
  ) as string;
  const paymentTypeParams =
    searchParams.get(BillingStripeQuery.paymentType) || "";
  const { mutate, isLoading } = useChoosePaymentMode();
  const { mutate: submutate, isLoading: subLoading } = useSubscriptionPayment();
  const { mutate: upgradeMutate, isLoading: upgradeLoading } =
    useBusinessBillingUpgrade();
  const { mutate: downgradeMutate, isLoading: downgradeLoading } =
    useBusinessBillingDowngrade();
  const { onClose, onOpen, open } = useOpener();

  const {
    onClose: downgradeModalOnClose,
    onOpen: downgradeModalOnOpen,
    open: downgradeModalOpen,
  } = useOpener();

  const onSubmit = (params: BillingType) => {
    if (params === BillingType.perApplication) {
      const isSubscriptionMode = false;
      const payload = {
        isSubscriptionMode,
        isPerApplicationMode: !isSubscriptionMode,
      };

      mutate(payload, {
        onSuccess: () => toast.success("Billing Successful"),
      });
    }

    if (params === BillingType.standard || params === BillingType.premium) {
      const payload = {
        billingType: params,
        isNigeria,
      };

      submutate(payload, {
        onSuccess: (data) => {
          window.location.replace(data.data.url);
        },
      });
    }
  };

  const onUpgrade = () => {
    const payload = {
      isNigeria,
    };

    upgradeMutate(payload, {
      onSuccess: () => toast.success("Upgrade Successful"),
    });
  };

  const onDowngrade = (staffEmails: string[]) => {
    const payload = { email: staffEmails, isNigeria };

    downgradeMutate(payload, {
      onSuccess: () => {
        downgradeModalOnClose();
        toast.success("Downgrade Successful");
      },
    });
  };

  const onClick = (params: BillingType) => {
    const checkGrade = () => {
      if (!businessUserData?.paymentInfo.subscriptionType) return;
      if (
        params === BillingType.premium &&
        businessUserData.paymentInfo.subscriptionType === BillingType.standard
      ) {
        onUpgrade();
      }

      if (
        params === BillingType.standard &&
        businessUserData.paymentInfo.subscriptionType === BillingType.premium
      ) {
        downgradeModalOnOpen();
      }
    };

    params === BillingType.perApplication
      ? onOpen()
      : businessUserData?.paymentInfo.subscriptionType ===
          BillingType.standard ||
        businessUserData?.paymentInfo.subscriptionType === BillingType.premium
      ? checkGrade()
      : onSubmit(params);
  };

  const masterLoading = isLoading || subLoading || upgradeLoading;

  useEffect(() => {
    const trxrefParam = searchParams.get(PayStackParam.trxref);
    const referenceParam = searchParams.get(PayStackParam.reference);

    if (trxrefParam) {
      searchParams.delete(PayStackParam.trxref);
    }

    if (referenceParam) {
      searchParams.delete(PayStackParam.reference);
    }
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <>
      <div className="flex flex-wrap items-center justify-between gap-6 mb-4">
        <Header>Manage Billing</Header>
      </div>

      {masterLoading ? (
        <SpinnerWrapper>
          <Spinner size={40} />
        </SpinnerWrapper>
      ) : (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 ">
          {billingData(
            !!businessUserData?.paymentInfo.isSubscriptionMode,
            getPrice(StaffSeatPrice)
          ).map((ev) => (
            <BillingCard
              currentPlan={businessUserData?.paymentInfo.subscriptionType}
              data={ev}
              onButtonClick={onClick}
            />
          ))}
        </div>
      )}

      {billingTypeParams && paymentTypeParams && <BillingSuccessModal />}
      {open && (
        <PerApplicationConfirmation
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={masterLoading}
          hasFreeTrail={!!businessUserData?.paymentInfo.isFreeTrialActive}
          hasCurrentPlan={businessUserData?.paymentInfo.subscriptionType}
        />
      )}
      {downgradeModalOpen && (
        <DowngradeModal
          onCloseModal={downgradeModalOnClose}
          onDowngrade={onDowngrade}
          isLoading={downgradeLoading}
        />
      )}
    </>
  );
};

export default Billing;

const Header = styled(ModuleTitle)`
  margin-bottom: 0;
`;
