import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import FormBtn from "../../../components/FormBtn";
import { saveDS160SecurityBackgroundP2Obj } from "../../../../../api/ds160/types";
import {
  useGetDS160SecurityBackgroundP2,
  useSaveDS160SecurityBackgroundP2,
} from "../../../../../hooks/ds160/usDS160";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
} from "../../../components/data";
import { VerticalFormWrapper } from "../styles";
import { SecurityBackgroundP2Validation } from "../validation";
import { resetDynamicFields } from "../../../components/data";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { getSecurityBackgroundValidations, processReviewData } from "../data";
import useAiReview from "../useAiReview";
import { ReviewSecuritySectionB } from "../Review/components/data";
import { BusinessQueryKey } from "../../../../../api/utils/types";
import { useSearchParams } from "react-router-dom";
import { isAnonClientDataStore } from "../../../../../store/isAnonClientData";
import { useSnapshot } from "valtio";

const SecurityBackgroundP2 = () => {
  const { toNextSection } = useToNextSection();
  const { isAnonClientData } = useSnapshot(isAnonClientDataStore);
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160SecurityBackgroundP2({
    applicationId: getApplicationId,
    buid: isAnonClientData,
  });
  const { mutate, isLoading: isSaving } = useSaveDS160SecurityBackgroundP2();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const onSubmit = (values: saveDS160SecurityBackgroundP2Obj) => {
    const payload = values;

    const section = ReviewSecuritySectionB(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      security: {
        ...riskData?.security,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId, buid: isAnonClientData },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: saveDS160SecurityBackgroundP2Obj = {
    partTwoI: data?.partTwoI || "",
    explanationPartTwoI: data?.explanationPartTwoI || "",
    partTwoII: data?.partTwoII || "",
    explanationPartTwoII: data?.explanationPartTwoII || "",
    partTwoIII: data?.partTwoIII || "",
    explanationPartTwoIII: data?.explanationPartTwoIII || "",
    partTwoIV: data?.partTwoIV || "",
    explanationPartTwoIV: data?.explanationPartTwoIV || "",
    partTwoV: data?.partTwoV || "",
    explanationPartTwoV: data?.explanationPartTwoV || "",
    partTwoVI: data?.partTwoVI || "",
    explanationPartTwoVI: data?.explanationPartTwoVI || "",
    partTwoVII: data?.partTwoVII || "",
    explanationPartTwoVII: data?.explanationPartTwoVII || "",
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || (!isAnonClientData && riskLoading)}
      isError={isError || (!isAnonClientData && riskError)}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Security and
            Background.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={SecurityBackgroundP2Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="Have you ever been arrested or convicted for any offence or
                    crime even though subject of a pardon amnesty or other
                    similar actions?"
                  name="partTwoI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partTwoI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartTwoI", value: "" },
                    ]);
                  }}
                />

                {formik.values.partTwoI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartTwoI"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you ever been related or engaged in a conspiracy to
                    violate any law relating to controlled substances?"
                  name="partTwoII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partTwoII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartTwoII", value: "" },
                    ]);
                  }}
                />

                {formik.values.partTwoII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartTwoII"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Are you coming to the United States to engage in
                    prostitution or unlawful commercialized vice or have you
                    been engaged in prostitution or procuring prostitutes within
                    the past 10 years?"
                  name="partTwoIII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partTwoIII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartTwoIII", value: "" },
                    ]);
                  }}
                />

                {formik.values.partTwoIII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartTwoIII"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you ever been involved in or do you seek to engage in
                    money laundering?"
                  name="partTwoIV"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partTwoIV"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartTwoIV", value: "" },
                    ]);
                  }}
                />

                {formik.values.partTwoIV === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartTwoIV"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you ever committed or conspire to commit a human
                    trafficking offence in the United States or outside United
                    States?"
                  name="partTwoV"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partTwoV"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartTwoV", value: "" },
                    ]);
                  }}
                />

                {formik.values.partTwoV === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartTwoV"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Have you ever loo in the aided abetted, assisted or colluded
                    with an individual who has committed or conspire to commit a
                    severe human trafficking offence in the United States or
                    outside the United States?"
                  name="partTwoVI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partTwoVI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartTwoVI", value: "" },
                    ]);
                  }}
                />

                {formik.values.partTwoVI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartTwoVI"
                      inputType="text-area"
                    />
                  </>
                )}

                <RadioComp
                  title="Are you the spouse son or daughter of an individual who has
                    committed of conspired to commit human trafficking offence
                    and United States or after United States and have you
                    wouldn't last 5 years mainly benefited from the trafficking
                    activities?"
                  name="partTwoVII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partTwoVII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartTwoVII", value: "" },
                    ]);
                  }}
                />

                {formik.values.partTwoVII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartTwoVII"
                      inputType="text-area"
                    />
                  </>
                )}

                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default SecurityBackgroundP2;

const Wrapper = styled.div``;

const Info = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 30px;
`;

const InfoBold = styled.div`
  font-weight: 700;
`;

const FormWrapper = styled(VerticalFormWrapper)``;
