import { WebPaths } from "../../../../routes/data";
import { FormCategories } from "../../../../routes/type";
import Colors from "../../../../styles/Colors";
import { loginFailed } from "../../../../utils";
import { RiNewsLine, RiUser6Line } from "react-icons/ri";
import { BiSolidBusiness } from "react-icons/bi";
import { AiOutlineComment } from "react-icons/ai";
import { IoInformationCircleOutline } from "react-icons/io5";
// import { RiCustomerService2Fill } from "react-icons/ri";

export interface NavItemsProps {
  label: string;
  url?: string;
  children?: {
    name: JSX.Element;
    subText: string;
    subChildren: {
      label: JSX.Element;
      url: string;
      formType?: string;
    }[];
  }[];
}

const temp = [
  {
    label: "For Individuals",
    children: [
      {
        name: (
          <div>
            Citizenship{" "}
            <span
              style={{
                color: Colors.Blue00,
              }}
            >
              Certificate
            </span>
          </div>
        ),
        subText: "Apply for citizenship & naturalization visa!",
        subChildren: [
          {
            label: (
              <div>
                Apply for Citizenship <strong>(Form N-400)</strong>
              </div>
            ),
            url: `${WebPaths.Eligibility}/${FormCategories.n400}`,
            formType: FormCategories.n400,
          },
          {
            label: (
              <div>
                Apply for Certificate of Citizenship{" "}
                <strong>(Form N-600)</strong>
              </div>
            ),
            url: `${WebPaths.Eligibility}/${FormCategories.n600}`,
            formType: FormCategories.n600,
          },
          {
            label: (
              <div>
                Replace Citizenship Document <strong>(Form N-565)</strong>
              </div>
            ),
            url: `${WebPaths.Eligibility}/${FormCategories.n565}`,
            formType: FormCategories.n565,
          },
          // {
          //   label: <div>Citizenship Resource Center</div>,
          //   url: WebPaths.Root,
          // },
        ],
      },
      {
        name: (
          <div>
            Travel{" "}
            <span
              style={{
                color: Colors.Blue00,
              }}
            >
              Visa
            </span>
          </div>
        ),
        subText: "Apply for your Travel Visa today!",
        subChildren: [
          {
            label: <div>BI/B2 Visa</div>,
            url: `${WebPaths.Eligibility}/${FormCategories.ds160}`,
            formType: FormCategories.ds160,
          },
          // {
          //   label: (
          //     <div>
          //       Online Nonimmigrant Visa Application{" "}
          //       <strong>(Form DS-160)</strong>
          //     </div>
          //   ),
          //   url: `${WebPaths.Eligibility}/${FormCategories.ds160}`,
          // },
          // {
          //   label: <div>Apply for Extend/Change Visa (Form I-539)</div>,
          //   url: WebPaths.Root,
          // },
          // {
          //   label: <div>Tourist Visa Resource Center</div>,
          //   url: WebPaths.Root,
          // },
        ],
      },
      {
        name: (
          <div>
            Student{" "}
            <span
              style={{
                color: Colors.Blue00,
              }}
            >
              Visa
            </span>
          </div>
        ),
        subText: "Apply for a student visa and start your academic journey!",
        subChildren: [
          {
            label: <div>F-1 Visa</div>,
            url: `${WebPaths.Eligibility}/${FormCategories.ds160}`,
            formType: FormCategories.ds160,
          },
          // {
          //   label: <div>J-1 Visa</div>,
          //   url: `${WebPaths.Eligibility}/${FormCategories.ds160}`,
          //   formType: FormCategories.ds160,
          // },
          {
            label: <div>M-1 Visa</div>,
            url: `${WebPaths.Eligibility}/${FormCategories.ds160}`,
            formType: FormCategories.ds160,
          },
          // {
          //   label: <div>Student Visa Resource Center</div>,
          //   url: WebPaths.Root,
          // },
        ],
      },
    ],
  },
  {
    label: "For Businesses",
    url: WebPaths.Businesses,
  },
  {
    label: "FiBi AI",
    children: [
      {
        name: (
          <div>
            FiBi{" "}
            <span
              style={{
                color: Colors.Blue00,
              }}
            >
              Text
            </span>
          </div>
        ),
        subText: "A text based interview preparation AI",
        subChildren: [
          {
            label: (
              <div>
                Get prepared with <strong>FiBi</strong>
              </div>
            ),
            url: WebPaths.FiBiAi,
            // formType: "",
          },
        ],
      },
      {
        name: (
          <div>
            FiBi{" "}
            <span
              style={{
                color: Colors.Blue00,
              }}
            >
              Voice
            </span>
          </div>
        ),
        subText: "An oral based interview preparation AI",
        subChildren: [
          {
            label: (
              <div>
                Get prepared with FiBi Voice{" "}
                {/* <strong
                  style={{
                    color: Colors.Blue00,
                  }}
                >
                  (Coming soon!)
                </strong> */}
              </div>
            ),
            url: WebPaths.FiBiVoice,
            formType: "",
          },
        ],
      },
    ],
    // url: WebPaths.FiBiAi,
  },
  {
    label: "About Us",
    url: WebPaths.About,
  },
  // {
  //   label: "Help",
  //   url: WebPaths.Help,
  // },
  {
    label: "Blogs",
    url: WebPaths.Blogs,
  },

  // {
  //   label: "Latest USCIS News",
  //   url: WebPaths.Root,
  // },
];

export const NavItems = (isBusiness: boolean): NavItemsProps[] => {
  const result = [...temp];
  if (isBusiness) {
    result.shift();
  }
  return result;
};

export interface ProfileNavProps {
  label: string;
  url?: string;
  children?: {
    name: JSX.Element;
    onClick?: () => void;
    url?: string;
  }[];
}

export const ProfileNav: ProfileNavProps[] = [
  {
    label: "My Account",
    children: [
      // {
      //   name: <div>Dashboard</div>,
      //   url: WebPaths.Profile,
      // },
      // {
      //   name: (
      //     <div className="flex flex-row items-center gap-2">
      //       <RiCustomerService2Fill /> Chat with support
      //     </div>
      //   ),
      //   // onClick: () => navigate(),
      //   url: `${WebPaths.Help}/?contact=true`,
      // },
      {
        name: <div>Sign Out</div>,
        onClick: loginFailed,
        // url: WebPaths.Auth,
      },
    ],
  },
];

export type NavbarItem = {
  label: string;
  icon?: JSX.Element;
  link: string;
};

export const navItems: NavbarItem[] = [
  {
    label: "For Individuals",
    icon: <RiUser6Line className="w-6 h-6" />,
    link: WebPaths.Individuals,
  },
  {
    label: "For Businesses",
    icon: <BiSolidBusiness className="w-6 h-6" />,
    link: WebPaths.Businesses,
  },
  {
    label: "FiBi AI",
    icon: <AiOutlineComment className="w-6 h-6" />,
    link: WebPaths.FiBiAi,
  },
  {
    label: "Blogs",
    icon: <RiNewsLine className="w-6 h-6" />,
    link: WebPaths.Blogs,
  },
  {
    label: "About",
    icon: <IoInformationCircleOutline className="w-6 h-6" />,
    link: WebPaths.About,
  },
];

export const getNavItems = (isBusiness: boolean): NavbarItem[] => {
  const result = [...navItems];
  if (isBusiness) {
    // result.shift();
    return [];
  }
  return result;
};
