import { BusinessApplicationId, ClentAnonId } from "../utils/types";

export interface saveInterviewPayload {
  id: string;
  payload: {
    prompt: string;
  };
}

export interface GetInterviewHistoryRes {
  hasError: boolean;
  code: number;
  result: string | GetInterviewHistoryResult[];
}

export interface PostRiskAssessmentRes {
  hasError: boolean;
  code: number;
  result: {
    role: string;
    content: string;
    refusal: null;
  };
}

export enum GetInterviewHistoryResultRole {
  user = "user",
  assistant = "assistant",
}

export interface GetInterviewHistoryResult {
  content: string;
  role: GetInterviewHistoryResultRole;
}

// export interface GetInterviewHistoryResult {
//   parts: GetInterviewHistoryPart[];
//   role: string;
// }

// export interface GetInterviewHistoryPart {
//   text: string;
// }

export interface RiskAssessmentGenericDS160
  extends RiskAssessmentPersonalInfoPayload {}

export interface RiskAssessmentDS160 {
  errorCheck?: boolean;
}

export interface AssessmentDs160Payload {
  data: Partial<AssessmentDs160DataPayload>;
  errorCheck?: boolean;
}

export interface AssessmentDs160DataPayload
  extends RiskAssessmentPersonalInfoPayload,
    RiskAssessmentGenericDS160,
    AssessmentDs160TravelPayload,
    AssessmentDs160AddressPhonePayload,
    AssessmentDs160PassportPayload,
    AssessmentDs160USContactPayload,
    AssessmentDs160FamilyPayload,
    AssessmentDs160WETPayload,
    AssessmentDs160SevisPayload,
    AssessmentDs160SecurityPayload,
    BusinessApplicationId,
    ClentAnonId {}

export interface RiskAssessmentPersonalInfoPayload {
  personal_Info: Record<string, string>;
}

export interface AssessmentDs160TravelPayload {
  travel: Record<string, string>;
}

export interface AssessmentDs160AddressPhonePayload {
  address_phone: Record<string, string>;
}

export interface AssessmentDs160PassportPayload {
  passport: Record<string, string>;
}

export interface AssessmentDs160USContactPayload {
  us_contact: Record<string, string>;
}

export interface AssessmentDs160FamilyPayload {
  family: Record<string, string>;
}

export interface AssessmentDs160WETPayload {
  wet: Record<string, string>;
}

export interface AssessmentDs160SevisPayload {
  sev: Record<string, string>;
}

export interface AssessmentDs160SecurityPayload {
  security: Record<string, string>;
}

export interface GetChatGptDs160AssessmentRes {
  hasError: boolean;
  code: number;
  result: string;
  // result: {
  //   role: string;
  //   content: string;
  //   refusal: null;
  // };
}
