import React, { FC, useEffect, useState } from "react";
import { useGetAllStaff } from "../../../../../../../hooks/business/useStaff";
import { mediaObj } from "../../../../../../../styles/Media";
import styled from "styled-components";
import { motion } from "framer-motion";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import Spinner from "../../../../../../../utils/Spinner";
import Colors from "../../../../../../../styles/Colors";
import { ImCancelCircle } from "react-icons/im";
import TerminateModal from "../../../../../components/TerminateModal";
import { Form, Formik } from "formik";
import SelectField from "../../../../../components/formik fields/SelectField";
import { StaffRes } from "../../../../../../../api/business/staff/types";
import { FormatingData, StaffSeatPrice } from "../../../../../components/data";
import { SingleOption } from "../../../../../../../types/types";
import { StaffToRetainValidation } from "./validation";
import RequestWrapper from "../../../../../components/RequestWrapper";
import useGetPrice from "../../../../../../../hooks/useGetPrice";

export interface StaffToRetainFormData {
  staffToRetain: SingleOption[] | null;
}

interface Props {
  onCloseModal: () => void;
  onDowngrade: (staffEmails: string[]) => void;
  isLoading: boolean;
}

const DowngradeModal: FC<Props> = ({
  onCloseModal,
  onDowngrade,
  isLoading,
}) => {
  const { getPrice } = useGetPrice();
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const {
    data: staffData,
    isLoading: staffLoading,
    isError: staffError,
  } = useGetAllStaff();

  const formattedStaffData =
    FormatingData<StaffRes>("email", "name", staffData) || [];

  const allClose = () => {
    onCloseModal();
    onClose();
  };

  useEffect(() => {
    if (staffData && staffData.length === 0) {
      onOpen();
    }
  }, [staffData]);

  //
  return open ? (
    <TerminateModal
      title="Switch to Standard Plan"
      subText="Are you sure you want to downgrade to the Standard Plan?"
      onClose={allClose}
      onConfirm={() => {
        onDowngrade([]);
      }}
      isLoading={isLoading}
      confirmBg={Colors.Blue00}
    />
  ) : (
    <Overlay onClose={allClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Cancel onClick={allClose}>
          <ImCancelCircle />
        </Cancel>
        {/* <Header name="Manage Billing" /> */}
        <RequestWrapper
          isLoading={staffLoading}
          isError={staffError}
          data={staffData}
        >
          <>
            <Header>Switch to Standard Plan</Header>
            <Para>
              You currently have staff members on the Premium Plan. <br /> On
              Standard Plan additional seats cost {getPrice(StaffSeatPrice)}
              /month, with a maximum of 2 paid seats allowed.
            </Para>

            {staffData && staffData.length > 0 && (
              <>
                <Formik
                  initialValues={
                    {
                      staffToRetain: null,
                    } as StaffToRetainFormData
                  }
                  enableReinitialize
                  validationSchema={StaffToRetainValidation}
                  onSubmit={(values) => {
                    const { staffToRetain } = values;

                    const emails = staffToRetain
                      ? staffToRetain.map((ev) => ev.value)
                      : [];
                    onDowngrade(emails);
                  }}
                >
                  <Form className="flex flex-col gap-4">
                    <SelectField
                      name="staffToRetain"
                      label="Please select the staff to retain"
                      options={formattedStaffData}
                      isLoading={staffLoading}
                      coloredLabel="(optional):"
                      isMulti
                    />
                    <BtnWrapper>
                      <CancelBtn
                        onClick={allClose}
                        disabled={isLoading}
                        type="button"
                      >
                        Cancel
                      </CancelBtn>
                      <ConfirmBtn disabled={isLoading} type="submit">
                        {isLoading ? (
                          <Spinner color={Colors.White} />
                        ) : (
                          "Switch to Standard"
                        )}
                      </ConfirmBtn>
                    </BtnWrapper>
                  </Form>
                </Formik>
              </>
            )}
          </>
        </RequestWrapper>
      </Wrapper>
    </Overlay>
  );
};

export default DowngradeModal;

const Wrapper = styled(motion.div)`
  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const Para = styled.p`
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  text-align: center;
  color: rgba(27, 9, 44, 0.9);
  margin-top: 16px;
  margin-bottom: 30px;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 8px;

  & > button {
    flex: 1;
  }

  ${mediaObj.bigMobile} {
    flex-wrap: wrap-reverse;
    gap: 10px;
  }
`;

const Btn = styled.button`
  border: none;
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 13px 45px;
  border-radius: 30px;
  white-space: nowrap;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CancelBtn = styled(Btn)`
  background: #e6e6e6;
  color: #1b092c99;
`;

const ConfirmBtn = styled(Btn)`
  width: 100%;
  background: ${Colors.Blue00};
  color: #ffffff;
  transition: all 0.3s ease;
  border: 1px solid ${Colors.Blue00};

  &:hover:not(:disabled) {
    color: ${Colors.Blue00};
    background: #ffffff;
  }
`;
