import React, { FC, useState } from "react";
import styled from "styled-components";
import { BillingDataProps, BillingType } from "./data";
import CalendlyPopUp from "../../../../../pages/For Business/components/CalendlyPopUp";
import { FaInfoCircle } from "react-icons/fa";
import useGetPrice from "../../../../../../../hooks/useGetPrice";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useBusinessCancelSubscription } from "../../../../../../../hooks/business/useBilling";
import useOpener from "../../../../../../../hooks/useOpener";
import TerminateModal from "../../../../../components/TerminateModal";
import { toast } from "react-toastify";

interface Props {
  data: BillingDataProps;
  currentPlan?: BillingType;
  onButtonClick?: (billingType: BillingType) => void;
  seatAdded?: number;
}

const BillingCard: FC<Props> = ({
  data,
  currentPlan,
  onButtonClick,
  seatAdded,
}) => {
  const { getPrice } = useGetPrice();
  const [open, setOpen] = useState(false);
  const {
    open: unsubscribeOpen,
    onClose: unsubscribeOnClose,
    onOpen: unsubscribeOnOpen,
  } = useOpener();
  const { mutate, isLoading } = useBusinessCancelSubscription();

  const onClose = () => {
    setOpen(false);
  };

  const isHighlighted = currentPlan === data.id;

  const onClick = () => {
    if (data.isCustom) {
      setOpen(true);
      return;
    }
    onButtonClick && onButtonClick(data.id);
  };

  const onUnsubscribe = () => {
    mutate(undefined, {
      onSuccess: () => {
        toast.success("Successful");
        unsubscribeOnClose();
      },
    });
  };

  return (
    <>
      <PricingCard
        isHighlighted={isHighlighted}
        className="relative transition-all hover:shadow"
      >
        {isHighlighted &&
          !data.isCustom &&
          data.id !== BillingType.perApplication && (
            <IoIosCloseCircleOutline
              className="absolute text-red-500 bg-white cursor-pointer -top-3 -right-3"
              size={30}
              onClick={unsubscribeOnOpen}
            />
          )}
        <PlanTitle>{data.title}</PlanTitle>
        <p className="flex items-baseline mb-4 gap-x-2">
          <span className={`text-3xl font-semibold tracking-tight`}>
            {data.isCustom ? "Custom Pricing" : getPrice(data.price)}
          </span>
          {data.pricePeriod && (
            <span className={`text-gray-500 text-base`}>
              /{data.pricePeriod}
            </span>
          )}
        </p>
        <FeatureList>
          {data.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </FeatureList>

        <div className="flex flex-col w-full gap-2 mt-auto">
          {isHighlighted && seatAdded && (
            <div className="flex items-center gap-2 text-sm text-primaryColor">
              <FaInfoCircle />
              Subscribed to {seatAdded} staff seats
            </div>
          )}

          <Button
            isHighlighted={isHighlighted}
            onClick={onClick}
            disabled={isHighlighted}
          >
            {isHighlighted ? "Current Plan" : data.buttonText}
          </Button>
        </div>
      </PricingCard>
      <CalendlyPopUp open={open} onClose={onClose} />
      {unsubscribeOpen && (
        <TerminateModal
          title={`Cancel ${data.title} Subscription`}
          subText={`Are you sure you want to cancel your ${data.title.toLowerCase()} subscription?`}
          onClose={unsubscribeOnClose}
          onConfirm={onUnsubscribe}
          isLoading={isLoading}
          // confirmBg={Colors.Blue00}
        />
      )}
    </>
  );
};

export default BillingCard;

const PricingCard = styled.div<{
  isHighlighted?: boolean;
}>`
  border: 1px solid ${(props) => (props.isHighlighted ? "#0073ff" : "#eeeeee")};
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const PlanTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

const FeatureList = styled.ul`
  list-style: disc;
  padding: 0;
  margin-bottom: 20px;
  margin-left: 16px;

  &:last-child {
    list-style: none;
  }

  li {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const Button = styled.button<{ isHighlighted?: boolean }>`
  background-color: ${(props) =>
    !props.isHighlighted ? "#0073ff" : "#f9fafb"};
  color: ${(props) => (props.isHighlighted ? "#6b7280" : "#fff")};
  border: 1px solid ${(props) => (!props.isHighlighted ? "#0073ff" : "#e5e7eb")};
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  cursor: ${(props) => (props.isHighlighted ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    /* background-color: #f9fafb;
    border: none; */
  }
`;
