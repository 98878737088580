import React from "react";
import Hero from "./components/Hero";
import Offers from "./components/Offers";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
import SEOMeta from "../../components/SEOMeta";
import { WebPaths } from "../../../../routes/data";
import { SVLogoSquare } from "../../../../images";
// import PricingTab from "../components/PricingTab";

const ForIndividuals = () => {
  return (
    <>
      <SEOMeta
        title="For Individuals | Empower Your Dreams, Get Your Visa Approved"
        description="Learn how SeamlessVisa can help individuals navigate the U.S. visa application process with ease, from risk checks to form reviews and interview preparation."
        canonicalUrl={`https://www.seamlessvisa.com${WebPaths.Individuals}`}
        url={`https://www.seamlessvisa.com${WebPaths.Individuals}`}
        siteName="SeamlessVisa"
        publisher="https://www.facebook.com/share/1BhLA5r2Ac/"
        imageUrl={SVLogoSquare}
        imageWidth="1920"
        imageHeight="1080"
        imageType="image/png"
        twitterCard="summary_large_image"
        twitterTitle="For Individuals | SeamlessVisa"
        twitterDescription="Learn how SeamlessVisa can help individuals navigate the U.S. visa application process with ease, from risk checks to form reviews and interview preparation."
        twitterSite="@SeamlessVisa_"
      />
      <div>
        <Hero />
        <Offers />
        <HowItWorks />
        {/* <PricingTab /> */}
        <Testimonials />
        {/* <About /> */}
        {/* <WhySeamless /> */}
        {/* <Mission /> */}
      </div>
    </>
  );
};

export default ForIndividuals;
